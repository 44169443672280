import { useMemo, useEffect, useState, useCallback } from "react";
import { Badge, Button } from "react-bootstrap";
import { changePriceFormat, numberWithCommas } from "utils/calculators";
import { pageView } from "../../GTM";
import { useCarts } from "_context/shoppingCart/cartProvider";

import "./style.scss";
import ModalProduct from "components/ModalProduct";
import styles from "./index.module.scss";
import { ALLERGENS } from "enumeration/allergies";

const { useTranslation } = require("react-i18next");

const ProductCard = ({
  //  handleRemoveCart, handleAddCart, 
  data,
  // isToCart,
  symbol }) => {
  const { addCount, cart, removeItem, addCart } = useCarts();
  const [show, setShow] = useState(false);

  const { t, i18n } = useTranslation();
  const isTranslate = useMemo(() => {
    const objData = {};
    data?.data?.translations?.forEach((x) => (objData[x?.language] = x));

    return objData;
  }, [data]);

  const textTranslate = isTranslate[i18n?.language];
  // console.log({ isTranslate, textTranslate }, i18n?.language);


  // const { addCount, cart, removeItem, addCart } = useCarts();



  const isToCart = cart?.object[data?.data?.id];
  if (isToCart) {
    const shoppingItem = cart?.shoppingCart?.find(
      (o) => o?.data?.id === data?.data?.id
    );
    // console.log(
    //     "Main DEBUG",
    //     cart,
    //     shoppingItem,
    //     "asdasdas2d"
    // );
    data["count"] = shoppingItem?.count;
    if (data?.data) data.data["count"] = shoppingItem?.count;
  }

  const handleAddCart = useCallback((param) => {
    addCart(param);
    // swal(t("successful"), t("productAddToCart"), "success");
  }, [addCart]);

  const handleRemoveCart = useCallback((param) => {
    removeItem(param);
    // swal(t("successful"), t("removeFromBasket"), "success");
  }, [removeItem, addCart]);

  useEffect(() => {
    pageView(window?.location?.pathname);
  }, []);

  const limitText = (text, limit) => {
    if (text.length <= limit) {
      return text;
    }
    return text.slice(0, limit) + "...";
  };

  console.log("isCartCard Data", data);

  let limitedMedicine = false;
  if (data?.data?.medicine && data?.count == 2) limitedMedicine = true;

  return (
    <div className="col-12 col-md-6 cursor-pointer mt-1">
      <div className="productCard d-flex flex-row">
        <div className="productCardImageBox" onClick={() => setShow(true)}>
          <img src={data?.data?.images} className="CardImage" alt={data?.data?.name} />
        </div>
        <div className="card-body radius">
          <div onClick={() => setShow(true)}>

            <h4 className="cardText">
              {limitText(textTranslate?.name || data?.data?.name, 26)}
            </h4>
            <p>
              {limitText(textTranslate?.shortDesc || data?.data?.shortDesc, 30)}
            </p>
            <p>{textTranslate?.desc || data?.data?.desc}</p>
            <div className="d-flex flex-row">
              {data?.data?.oldPrice ? (
                <h4 className="orgPrice">
                  {"£"}{numberWithCommas(changePriceFormat(data?.data?.oldPrice))}
                </h4>
              ) : (
                ""
              )}
              <h4 className="price">
                {"£"}{numberWithCommas(changePriceFormat(data?.data?.price))}
              </h4>
            </div>



          </div>

          <div className={styles.allergies}>
            {
              data?.data?.allergies?.map((x) => {
                return (
                  <div className={styles.allergies_item} key={x}>
                    <img src={ALLERGENS[x]?.icon} alt={ALLERGENS[x]?.label} className={styles.allergies_icon} />
                    {t(ALLERGENS[x]?.label)}
                  </div>
                )
              })
            }
          </div>

          <span className="addBasketButtons d-flex align">
            {data?.count > 1 ? (
              <button
                type="button"
                className="left dec quantityButtonMinus comeTop"
                id="adetEksi"
                data-urunid={19}
                onClick={() => addCount(data?.data, data?.count - 1)}
              >
                <i className="fa-solid fa-minus" />
              </button>
            ) : (
              <button
                type="button"
                className="left dec quantityButtonMinus comeTop"
                id="adetEksi"
                data-urunid={19}
                onClick={() => handleRemoveCart(data?.data)}
              >
                <i className="fa-solid fa-minus" />
              </button>
            )}

            <Button className="quantityButton">{data?.count}</Button>
            <button
              type="button"
              className=" right inc quantityButtonPlus"
              id="adetArti"
              data-urunid={19}
              // onClick={() => addCount(data?.data)}
              disabled={limitedMedicine}
              onClick={() => {
                !limitedMedicine && addCount(data?.data)
              }}
            >
              {" "}
              <i className="fa-solid fa-plus" />
            </button>
          </span>
          {/* {isToCart ? (
            <span className="addBasketButtons d-flex align">
              {data?.count > 1 ? (
                <button
                  type="button"
                  className="left dec quantityButtonMinus comeTop"
                  id="adetEksi"
                  data-urunid={19}
                  onClick={() => addCount(data, data?.count - 1)}
                >
                  <i className="fa-solid fa-minus" />
                </button>
              ) : (
                <button
                  type="button"
                  className="left dec quantityButtonMinus comeTop"
                  id="adetEksi"
                  data-urunid={19}
                  onClick={() => handleRemoveCart(data)}
                >
                  <i className="fa-solid fa-minus" />
                </button>
              )}

              <Button className="quantityButton">{data?.count}</Button>
              <button
                type="button"
                className=" right inc quantityButtonPlus"
                id="adetArti"
                data-urunid={19}
                onClick={() => addCount(data)}
              >
                {" "}
                <i className="fa-solid fa-plus" />
              </button>
            </span>
          ) : (
            <Button
              onClick={() => handleAddCart(data)}
              className="float-end addBasketButton"
            ></Button>
          )} */}
        </div>
      </div>
      <ModalProduct {...{ symbol, show, setShow, handleRemoveCart, handleAddCart, addCount, data: data?.data, changePriceFormat, textTranslate, isToCart }} />

    </div>
  );
};

export default ProductCard;


// import { useMemo, useEffect, useState } from "react";
// import { Badge, Button } from "react-bootstrap";
// import { changePriceFormat } from "utils/calculators";
// import { pageView } from "../../GTM";
// import { useCarts } from "_context/shoppingCart/cartProvider";

// import "./style.scss";
// import ModalProduct from "components/ModalProduct";

// const { useTranslation } = require("react-i18next");

// const ProductCard = ({ handleRemoveCart, handleAddCart, data, isToCart }) => {
//   const { addCount, cart, removeItem, addCart } = useCarts();
//   const [show, setShow] = useState(false);

//   const { t, i18n } = useTranslation();
//   const isTranslate = useMemo(() => {
//     const objData = {};
//     data?.data?.translations?.forEach((x) => (objData[x?.language] = x));

//     return objData;
//   }, [data]);

//   const textTranslate = isTranslate[i18n?.language];
//   // console.log({ isTranslate, textTranslate }, i18n?.language);

//   useEffect(() => {
//     pageView(window?.location?.pathname);
//   }, []);

//   const limitText = (text, limit) => {
//     if (text?.length <= limit) {
//       return text;
//     }
//     return text?.slice(0, limit) + "...";
//   };

//   console.log("isCartCard Data", data);

//   return (
//     <div className="col-12 col-md-6 cursor-pointer mt-5">
//       <div className="productCard d-flex flex-row">
//         <div className="" onClick={() => setShow(true)}>
//           <img src={data?.data?.images} className="CardImage" alt={data?.name} />
//         </div>
//         <div className="card-body ">
//           <div onClick={() => setShow(true)}>

//             <h4 className="cardText">
//               {limitText(textTranslate?.name || data?.data?.name, 26)}
//             </h4>
//             <p>
//               {limitText(textTranslate?.shortDesc || data?.data?.shortDesc, 30)}
//             </p>
//             <p>{textTranslate?.desc || data?.data?.desc}</p>
//             <div className="d-flex flex-row">
//               {data?.data?.oldPrice ? (
//                 <h4 className="orgPrice">
//                   {"£"} {changePriceFormat(data?.data?.oldPrice)}
//                 </h4>
//               ) : (
//                 ""
//               )}
//               <h4 className="price">
//                 {"£"}     {changePriceFormat(data?.data?.price)}
//               </h4>
//             </div>
//           </div>

//           <span className="float-right d-flex align" style={{
//             marginTop: "-25px"
//           }}>
//             {data?.count > 1 ? (
//               <button
//                 type="button"
//                 className="left dec quantityButtonMinus comeTop"
//                 id="adetEksi"
//                 data-urunid={19}
//                 onClick={() => addCount(data, data?.count - 1)}
//               >
//                 <i className="fa-solid fa-minus" />
//               </button>
//             ) : (
//               <button
//                 type="button"
//                 className="left dec quantityButtonMinus comeTop"
//                 id="adetEksi"
//                 data-urunid={19}
//                 onClick={() => handleRemoveCart(data)}
//               >
//                 <i className="fa-solid fa-minus" />
//               </button>
//             )}

//             <Button className="quantityButton">{data?.count}</Button>
//             <button
//               type="button"
//               className=" right inc quantityButtonPlus"
//               id="adetArti"
//               data-urunid={19}
//               onClick={() => addCount(data)}
//             >
//               {" "}
//               <i className="fa-solid fa-plus" />
//             </button>
//           </span>
//         </div>
//       </div>
//       <ModalProduct {...{ show, setShow, handleRemoveCart, handleAddCart, addCount, data: data?.data, changePriceFormat, textTranslate, isToCart }} />

//     </div>
//   );
// };

// export default ProductCard;
