import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./privacy.module.scss";
import NavBar from "components/navbar";
// import BottomNav from "components/bottomNav";

export default function Privacy() {
	return (
		<>
			<div className={["container"].join(" ")}>
				<NavBar fromTerms />
				<Row>
					<Col>
						<h1 className={styles.headline}>Privacy Policy</h1>

						<p className={styles.paragraph}>
							<strong>Who we are</strong>
							<br />
							We are Papyon Room Services. Our platform address is: www.room-service.solutions
							<br />
							<strong>Order,Note and Comments</strong>
							<br />
							<p>
								When visitors order or leave notes of order on the site we collect the data shown in the order, and also the visitor&rsquo;s name, phone
								number, e-mail address, and the room number. We need to keep this data to seamlessly deliver our Services as explained in our Terms and
								Conditions.&nbsp;
							</p>
							<p>Cookies&nbsp;</p>
							<p>
								If you order on our site you opt-in to saving your name, phone number, e-mail address, and the hotel ID in cookies. These cookies will last
								for one year. If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains
								no personal data and is discarded when you close your browser.
							</p>
							<p>Who we share your data with</p>
							<p>Papyon does not share your data with any third party.</p>
							<p>What rights you have over your data</p>
							<p>
								If you ordered using our website, you can request to receive an exported file of the personal data we hold about you, including any data you
								have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to
								keep for administrative, legal, or security purposes.
							</p>
						</p>

						{/* <p className={styles.paragraph}>
              <strong>Who we are</strong>
              <br /> Our platform address is: www.room-service.solutions
              <br />
              <strong>Order,Note and Comments</strong>
              <br />
              When visitors order or leave notes of order on the site we collect
              the data shown in the order, and also the visitor’s name and the
              room number. Cookies If you order on our site you opt-in to saving
              your name and the hotel ID in cookies. These cookies will last for
              one year. If you visit our login page, we will set a temporary
              cookie to determine if your browser accepts cookies. This cookie
              contains no personal data and is discarded when you close your
              browser.
              <br /> <strong> Who we share your data with</strong> <br />
              Papyon does not share your data with any third party.
              <br /> <strong>What rights you have over your data</strong>
              <br /> If you ordered using our website, you can request to
              receive an exported file of the personal data we hold about you,
              including any data you have provided to us. You can also request
              that we erase any personal data we hold about you. This does not
              include any data we are obliged to keep for administrative, legal,
              or security purposes.
            </p> */}
					</Col>
				</Row>
			</div>
		</>
	);
}
