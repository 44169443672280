import { useUtmSource } from "_context/utmSource/uuidProvider";
import FullScreenPopup from "components/languagePopup";
import { getHotel } from "api/api";
import { useQuery } from "react-query";
import { useUuid } from "_context/uuid/uuidProvider";
import ErrorTime from "components/ErrorTime";
import { useTranslation } from "react-i18next";

const Middleware = ({ children }) => {
	const { t, i18n } = useTranslation();

	const { data: UtmData, setLanguage } = useUtmSource();
	const { data: uuid } = useUuid();
	const { data, isError, error, isLoading } = useQuery("hotel", () => getHotel(uuid), {
		enabled: !!uuid,
		retry: false,
		onSuccess: (data) => {
			if (!UtmData?.language && data?.defaultLanguage) {
				// alert("Change language");
				// console.log("* * * Middleware : ", { data, lang: UtmData?.language });
				i18n.changeLanguage("en"); //data?.defaultLanguage
				setLanguage("en");
			}
			// defaultLanguage
		},
	});
	// console.log("* * * Middleware * * *", { data, isError, error, isLoading });

	if (isError) {
		return <ErrorTime message={error?.response?.data?.message} />;
	}
	// if (!UtmData?.language) return <FullScreenPopup isOpen={!UtmData?.language} onChange={(e) => setLanguage(e)} />;

	return (
		<>
			{/* <FullScreenPopup
				isOpen={!UtmData?.language}
				onChange={(e) => {
					setLanguage(e);
					//
				}}
			/> */}
			{children}
		</>
	);
};

export default Middleware;
