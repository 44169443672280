import { useDiscount } from "_context/discount/discountProvider";
import { getDiscount } from "api/api";
import { useMutation } from "react-query";
import styles from "./index.module.scss";
import FieldText from "components/FieldText";
import { fieldNames, validation } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Alert from "react-bootstrap/Alert";
import { useEffect, useState } from "react";

const Discount = ({ toggle }) => {
	const [show, setShow] = useState(true);
	const [stateData, setStateData] = useState();
	const { t, i18n } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(validation()),
		mode: "onSubmit",
	});

	const discountState = useDiscount();
	let isDiscount = discountState?.data?.amount != false && discountState?.data?.amount >= 0;

	const { isLoading, mutate, error } = useMutation(
		"discount",
		(data) => {
			// console.log("* * * discount :", { data });
			return getDiscount(data);
		},
		{
			onSuccess: (data) => {
				// setStateData({
				//     name: data.name,
				//     code: data.code,
				//     amount: data.amount,
				// })

				discountState.setData({
					name: data.name,
					code: data.code,
					amount: data.amount,
					discountType: data?.discountType,
					isUnit: data?.discountType === "unit",
				});
				toggle();

				console.log("* * * discount onSuccess:", { data });
			},
		}
	);

	const onSubmit = () => {
		const values = getValues();

		mutate(values.code);
	};

	const cancel = () => {
		setStateData();
		discountState.initial();
	};

	const acceptThisCode = () => {
		discountState.setData(stateData);
		toggle();
	};

	useEffect(() => {
		if (!isDiscount) return;
		setStateData(discountState?.data);
	}, []);
	console.log({ error });

	return (
		<div className={styles.box}>
			{stateData ? (
				<>
					<Alert show={!!stateData} variant="success">
						<Alert.Heading>{t("success")}</Alert.Heading>
						<p>
							{t("discount-code-details", {
								code: stateData?.code,
								amount: stateData?.amount,
								name: stateData?.name,
							})}
						</p>
						<hr />
						<div className="d-flex justify-content-end">
							<Button onClick={cancel} variant="outline-waning">
								{t("cancel")}
							</Button>

							<Button onClick={acceptThisCode} variant="outline-success">
								{t("accept")}
							</Button>
						</div>
					</Alert>
				</>
			) : (
				<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
					<Alert show={show} variant="success">
						<p>{t("discount-note")}</p>
					</Alert>

					<FieldText
						require
						{...{
							name: fieldNames.code,
							register,
							placeholder: " ",
							label: "code",
							errors,
						}}
					/>

					{error?.response?.data ? (
						<Alert key={"info"} variant={"info"}>
							{error?.response?.data?.message}
						</Alert>
					) : (
						""
					)}

					<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
						{!isLoading ? t("checkAuthor", { author: t("code") }) : "Loading..."}
					</Button>
				</form>
			)}
		</div>
	);
};

export default Discount;
