import json from "assets/json";
import countriesSelector from "components/selector/contries";
import React, { createContext, useState, useContext } from "react";
import { useEffect } from "react";

let defaultValue;

export const countries = {
	options: json.countries.map((x) => {
		if (x.code === "GB") defaultValue = countriesSelector.convertor.object(x);
		return countriesSelector.convertor.object(x);
	}),
	defaultValue,
};

const DeliveryContext = createContext();
export const useDelivery = () => useContext(DeliveryContext);

function getInitialState() {
	const notes = localStorage.getItem("delivery");
	let data = notes
		? JSON.parse(notes)
		: {
				roomNumber: "",
				staticRoomNumber: false,
				name: "",
				country: countries.defaultValue,
				// email: "",
		  };
	if (!data?.country) {
		data = data || {};
		data.country = countries.defaultValue;
	} else {
		data = data || {};
		data.country = data.country?.data ? countriesSelector.convertor.object(data.country?.data) : countries.defaultValue;
	}
	// data.country = false; //countries.defaultValue;
	console.log("* * * DeliveryContext :", { data });
	return data;
}

export default function DeliveryProvider({ children }) {
	const [data, setData] = useState(getInitialState);

	useEffect(() => {
		localStorage.setItem("delivery", JSON.stringify({ ...data, country: data?.country?.data && { data: data?.country?.data } }));
	}, [data]);

	console.log({ data });

	const onChange = (e) => {
		setData((x) => ({ ...x, [e?.name || e.target.name]: e?.value || e.target.value }));
	};

	const setRoomNumber = (data) => {
		setData((x) => ({ ...x, roomNumber: data, staticRoomNumber: data }));
	};

	const initial = (force) =>
		setData({
			...(force
				? {
						staticRoomNumber: "",
						roomNumber: "",
						country: countries.defaultValue,
				  }
				: {
						staticRoomNumber: data.staticRoomNumber || "",
						roomNumber: data.staticRoomNumber || "",
						country: countries.defaultValue,
				  }),
			name: "",
		});

	return <DeliveryContext.Provider value={{ data, setData, setRoomNumber, onChange, initial }}>{children}</DeliveryContext.Provider>;
}
