import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalLayout({ title, children, show, setShow, dialogClassName }) {

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName={dialogClassName}
        >
            <Modal.Header >
                <Modal.Title >
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
}

export default ModalLayout;