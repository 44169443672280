// import { useTranslation } from "react-i18next";
// import { useState, useEffect } from "react";
// import { pageView } from "../../GTM";
// import "./style.scss";

// const ProductCard = ({ handleRemoveCart, handleAddCart, data, isToCart }) => {
//   const { t, i18n } = useTranslation();

//   useEffect(() => {
//     pageView(window?.location?.pathname);
//   }, []);

//   return (
//     <div className="recommendedProductCard">
//       <div className="imageContainer" onClick={() => handleAddCart(data)}>
//         <img
//           src={data?.images}
//           className="recommendCardImage"
//           alt={data?.name}
//         />
//           <div className={styles.price}>
//           {data?.price} {symbol}
//         </div>
//         <button
//           onClick={() => handleAddCart(data)}
//           className="recommendedAddBasketButton"
//         >
//           +
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ProductCard;

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { pageView } from "../../GTM";
import styles from "./index.module.scss";
import ImageFullLoad from "components/ImageFullLoad";
import { changePriceFormat, numberWithCommas } from "utils/calculators";

const RecommendedProductCard = ({ handleRemoveCart, handleAddCart, data, symbol, isToCart, isLoading }) => {
	const { t, i18n } = useTranslation();

	useEffect(() => {
		pageView(window?.location?.pathname);
	}, []);

	console.log("* * * RecommendedProductCard :", { data, symbol });

	return (
		<div className={styles["recommendedProductCard"]}>
			<div className={styles["imageContainer"]} onClick={() => handleAddCart(data)}>
				{/* <img
          src={data?.images}
          className="recommendCardImage"
          alt={data?.name}
        /> */}
				<ImageFullLoad
					src={data?.images}
					alt={data?.name}
					className={styles["image"]}
					parentProps={{
						className: styles["recommendCardImage"],
					}}
				/>
				<div className={styles.price}>£ {numberWithCommas(changePriceFormat(data?.price))}</div>
				{!isLoading ? (
					<button onClick={() => handleAddCart(data)} className={styles["recommendedAddBasketButton"]}>
						+
					</button>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default RecommendedProductCard;
