import { ERRORS } from "enumeration";
import * as yup from "yup";

export const fieldNames = {
	name: "name",
	email: "email",
	roomNumber: "roomNumber",
	note: "note",
	phoneNumber: "phoneNumber",
	privacy: "privacy",
	country: "country",
};

export const validation = (required) => {
	let validationSchema = {
		[fieldNames.name]: yup.string().required(ERRORS.isRequire(fieldNames.name)).label(fieldNames.name),
		[fieldNames.email]: yup.string().email(ERRORS.isNotValid(fieldNames.email)),
		[fieldNames.roomNumber]: yup.string().required(ERRORS.isRequire(fieldNames.roomNumber)).label(fieldNames.roomNumber),
		[fieldNames.note]: yup.string().label(fieldNames.note),
		[fieldNames.privacy]: yup.boolean().isTrue(ERRORS.isRequire()).required(ERRORS.isRequire()).label(fieldNames.privacy),
	};
	if (required?.email)
		validationSchema = {
			...validationSchema,
			[fieldNames.email]: yup.string().email().required(ERRORS.isRequire(fieldNames.email)).label(fieldNames.email),
		};

	if (required?.phoneNumber)
		validationSchema = {
			...validationSchema,
			[fieldNames.phoneNumber]: yup.string().required(ERRORS.isRequire(fieldNames.email)).label(fieldNames.email),
		};

	return yup.object().shape(validationSchema);
};
