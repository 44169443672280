import NavBar from "components/navbar";
import React from "react";
import styles from "./terms.module.scss";

export default function DistanceSelling() {
	return (
		<>
			<div className={["container"].join(" ")}>
				<NavBar fromTerms />
				<div className={styles.terms}>
					<p>
						<h3>Terms and Conditions</h3>
					</p>
					<p>
						Greetings and welcome to Papyon! This page, along with the accompanying documents it refers to, outlines the terms (&quot;Terms&quot;) that
						govern your orders of our menu items (&quot;Items&quot;) through our websites you can log into via the QR codes available at hotel rooms or
						associated services, collectively known as an &quot;Application.&quot; Please read these Terms carefully before using our Application.
						<br />
						If you have any questions relating to these Terms please contact <a href="mailto:info@papyonsolutions.com">info@papyonsolutions.com</a> . If you
						are a consumer, your legal rights are not affected by these Terms, which apply in addition to them and do not replace them. By ordering through
						Papyon application, you confirm that you accept these Terms.
					</p>
					<p>
						<h3>1. Information About Us</h3>
					</p>
					<p>
						Papyon Room Service Solutions Limited, a company incorporated and registered in England and Wales, whose registered office is at 151 West Green
						Road, Seven Sisters, London, England, UK, N15 5EA. Our Company registration number is 15074533. You may contact us at{" "}
						<a href="mailto:info@papyonsolutions.com">info@papyonsolutions.com</a>.
					</p>
					<p>
						<h3>2. Purpose</h3>
					</p>
					<p>
						Our objective is to link you to the businesses we partner with (&ldquo;Suppliers&quot;) and allow you to order Items for delivery (our
						&ldquo;Service&rdquo;). Where you order from a Supplier, Papyon acts as an agent on behalf of that Supplier to conclude your order from our
						Application and to manage your experience throughout the order process. Once you have placed an order, delivery of your Items will be arranged
						by Papyon and its couriers.
					</p>
					<p>
						<h3>3. Your Personal Information</h3>
					</p>
					<p>
						Before you can place orders for Items using our Application, you need to provide your name, phone number, e-mail address, hotel room number and
						credit card details if you opt for that method of payment.
					</p>
					<p>
						Papyon reserves the right, where necessary and without limitation, to undertake all such necessary action including requiring further
						verification as to the identity, age, and other relevant details of a customer and as is reasonable to protect itself against fraudulent or
						illegal behaviour or where we have reasonable grounds to believe that, in dealing with such a customer, we may be in actual or possible breach
						of a law or regulation that applies to us. You must provide us with any information we reasonably ask for as soon as possible. We&rsquo;ll use
						your information as per our Privacy Policy.
					</p>
					<p>
						<h3>4. Service Availability</h3>
					</p>
					<p>
						Each Supplier has a prescribed delivery area. This delivery area may change at any time due to factors such as weather, or demand on our
						service. This is to ensure that Items reach your hotel at their best. Suppliers each decide their own operating hours. That means that the
						availability of our Service, and the range of Suppliers from which you can order, depends on the Suppliers in your area. If you try to order a
						delivery to a location outside the delivery area or operating hours of a Supplier, or the Application is otherwise unavailable for any reason,
						we will notify you that ordering will not be possible.
					</p>
					<p>
						<h3>5. Orders</h3>
					</p>
					<p>
						You must be aged 18 or older to use our Site and Service. By placing an order through our Application, you confirm that you are aged 18 or
						older. The contract for the supply of any Item you have ordered comes into existence when we send the Confirmation Notice. You are responsible
						for paying for all Items ordered by you, and for related delivery charges, and for complying with these Terms, even if you have ordered the Item
						for someone else. Papyon has the discretion to operate with a minimum order value policy. This will be displayed on our Application. All Items
						are subject to availability. Suppliers may use nuts or other allergens in the preparation of certain Items. Increasing numbers of Suppliers will
						be displaying dish by dish allergens information. Where that information is not available or if you have further questions, please contact us
						prior to ordering if you have an allergy. Papyon cannot guarantee that any of the Items sold by our Suppliers are free of allergens.
					</p>
					<p>
						<h3>6. Delivery</h3>
					</p>
					<p>
						When you place an order, we will tell you an estimated delivery time for your Item before you place the order, but we will attempt delivery as
						soon as possible; you must therefore be available to accept delivery from the time you place the order. We will tell you the time when the Item
						is expected to be delivered; you must be available to accept delivery for [ten (10) minutes before and ten (10) minutes after that time].
						It&apos;s important that you always check our application for accurate delivery times, as this can vary for a number of reasons including meal
						preparation time, traffic and weather conditions and courier availability.
					</p>
					<p>
						Unfortunately, despite our, and the Supplier&rsquo;s best efforts, things do not always go to plan and factors may prevent us from delivering
						your Item on time. If your order is more than fifteen (15) minutes later than the time we communicated to you when you placed the order, and we
						haven&rsquo;t notified you giving you the option to cancel your order, we will work with you to make things right unless you have caused the
						delay (e.g. because you gave us the wrong address or did not come to the door).
					</p>
					<p>
						We will attempt delivery at the address you provide to us when you place your order. It is not possible to change the address after giving the
						order.
					</p>
					<p>
						You will still be charged for the Item and for delivery in the event of a failed delivery if you have caused such failure for any reason.
						Reasons you might cause a delivery to fail include (but are not limited to):
						<br />
						&bull; You do not come to the door, did not pick up the phone when the courier contacted you using the contact information you have provided us
						and/or you picked up the phone but then failed to provide access within a reasonable amount of time, and the courier is unable to find a safe
						location to leave the food.
					</p>
					<p>
						<h3>7. Your Rights if Something is Wrong With Your Items</h3>
					</p>
					<p>
						You have a legal right to receive goods which comply with their description, which are of satisfactory quality and which comply with any
						specific requirements you tell us about (and we agree to) before you place your order. If you believe that the Items you have been delivered do
						not comply with these legal rights, please let us know. We may request a photograph showing the problem if it is something that can be seen by
						inspecting the Items. We will provide a refund or account credit in respect of the affected part of the Item, and also in respect of delivery if
						the whole order was affected, unless we have reasonable cause to believe that the problem was caused after delivery.
					</p>
					<p>
						Prior to processing your refund or account credit, we may take into account relevant factors including the details of the order, including your
						account history, what happened on delivery and information from the Supplier.
					</p>
					<p>
						<h3>8. Cancellation</h3>
					</p>
					<p>
						You may cancel an order without charge at any time before the Supplier has started preparing the food (a &quot;Started Order&quot;). If you wish
						to cancel an order before it becomes a Started Order, please contact the Hotel immediately.
					</p>
					<p>
						You may cancel an order without charge at any time before the Supplier has started preparing the food (a &quot;Started Order&quot;). If you wish
						to cancel an order before it becomes a Started Order, please contact the Hotel immediately.
					</p>
					<p>
						If the Supplier confirms the order was not a Started Order, we will refund your payment (excluding any discount, or Voucher that was applied to
						the order . If you cancel any order after it becomes a Started Order, you will be charged the full price for the Items, and if the courier has
						been dispatched you will also be charged for delivery.
					</p>
					<p>
						Papyon and the Supplier may notify you that an order has been cancelled at any time. You will not be charged for any orders cancelled by us or
						the Supplier, and we will reimburse you for any payment already made using the same method you used to pay for your order.
					</p>
					<p>
						<h3>9. Prices, Payment and Offers</h3>
					</p>
					<p>
						Prices include VAT. You confirm that you are using our Service for personal, non-commercial use. We reserve the right to charge a Service Fee,
						which may be subject to change, for the provision of our Services. You will be notified of any applicable Service Fee and taxes prior to
						purchase on the checkout page on our Application. No changes will affect existing confirmed orders, unless there is an obvious pricing mistake.
						If there is an obvious pricing mistake, we will notify you as soon as we can and you will have the choice of confirming the order at the
						original price or cancelling the order without charge and with a full refund of any money already paid. Where courier makes a delivery, we may
						also charge you a delivery fee. This will be notified to you during the order process before you complete your order.
					</p>
					<p>
						The total price of your order will be set out on the checkout page on our Application, including the prices of Items and Delivery and applicable
						Service Fees and taxes.
					</p>
					<p>
						Payment for all Items and deliveries can be made on our Application by credit or debit card, or other payment method made available by Papyon.
						Once your order has been confirmed your credit or debit card will be authorised and the total amount marked for payment. Payment is made
						directly to Papyon acting as agent on behalf of the Supplier only.
					</p>
					<p>
						We are authorised by the Suppliers to accept payment on their behalf and payment of the price of any Items or delivery charges to us will fulfil
						your obligation to pay the price to the Supplier.
					</p>
					<p>
						<h3>10. Our Responsibility for Loss or Damage That You Suffer</h3>
					</p>
					<p>
						We are responsible to you for any loss or damage that you suffer that is a foreseeable result of our breaking these Terms or of failing to use
						reasonable care and skill in relation to your use of our Service. We are not responsible for any loss or damage that is not foreseeable. Loss or
						damage is &ldquo;foreseeable&rdquo; if it is either obvious that it will happen, or if you told us that it might happen, for example if you tell
						us about particular circumstances that might increase the loss or damage arising from our breach of these Terms before you place an order.
					</p>
					<p>
						We do not exclude or limit our responsibility to you for loss or damage where it would be unlawful to do so. This includes any responsibility
						for death or personal injury caused by our failure, or our employees&rsquo;, agents&rsquo; or subcontractors&rsquo; failure, to use reasonable
						care and skill; for fraud or fraudulent misrepresentation; for breach of your legal rights in relation to the Items; or for defective Items
						under the Consumer Protection Act 1987. Subject to the previous paragraph, we are not responsible for any loss or damage that you suffer as a
						result of your own breach of these Terms, or as a result of any IT hardware or software failure other than a failure in our Applications.
					</p>
					<p>
						<h3>11. Data Protection</h3>
					</p>
					<p>We process your personal data in accordance with our Privacy Policy.</p>
					<p>
						<h3>12. Other Terms</h3>
					</p>
					<p>
						If either we or you have any right to enforce these Terms against the other, that right will not be lost even if the person who has the right
						delays enforcing it, or waives their right to enforce it in any instance. If a court or other authority decides that any part of these Terms is
						illegal or ineffective, the rest of the terms will be unaffected and will remain in force.
					</p>
					<p>
						We may change these Terms from time to time. If we make any changes which affect your rights in relation to our Service, we will notify you.
						Changes to the Terms will not affect any orders. These Terms are governed by English law and you should bring legal proceedings in relation to
						our Service in the English courts.
					</p>
					<strong>Last updated: [26 September 2023]</strong>
				</div>
			</div>
		</>
	);
}
