import { useEffect, useState } from "react";

import "./App.css";
import Layout from "./components/layout/Layout";
import Category from "./components/category";
import Products from "./components/product/product";
import ChildCategory from "./components/childCategory";
import Cart from "./components/cart/cardNew";
import Delivery from "./components/delivery/delivery";
import Payment from "./components/payment/payment";
import Done from "./components/done/done";
import Advices from "./components/advices/advices";
import Wheather from "./components/wheather/wheather";
import {
  Route,
  Routes,
  Switch,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { IS_CATALOG, IS_MAGNET, UTM_SOURCE, UUID_KY } from "./enumeration";
import { useUuid } from "_context/uuid/uuidProvider";
import ErrorTime from "components/ErrorTime";
import { useTranslation } from "react-i18next";
import { useCategory } from "_context/category/provider";
import { usePayment } from "_context/payment/paymentProvider";
import { useDelivery } from "_context/delivery/deliveryProvider";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useUtmSource } from "_context/utmSource/uuidProvider";
// import snow from "./../src/assets/snow.png";
import Privacy from "components/policies/privacy";
import DistanceSelling from "components/policies/distanceSelling";
import Return from "components/policies/return";
import OnlinePayment from "components/onlinePayment";
import About from "components/policies/about";
import NewCategory from "components/category/new";
import Middleware from "components/Middleware";
// import Snowfall from "react-snowfall";

function App() {
  // const [images, setImages] = useState()
  let [searchParams] = useSearchParams();
  let location = useLocation();
  let navigate = useNavigate();
  const [t] = useTranslation();
  // const snowImages = [snow];
  const utmSource = searchParams.get(UTM_SOURCE);
  console.log(utmSource);
  const { setData, data: data } = useUuid();
  const {
    initial: UtmInitial,
    data: UtmData,
    setData: utmSetData,
  } = useUtmSource();

  // ----------------------- modal

  const { initial: dInitial, setRoomNumber, data: dData } = useDelivery();
  const { initial: pInitial } = usePayment();
  const { initial: catInitial } = useCategory();
  const { initial: cInitial } = useCarts();

  // reading data from URL

  // const headerUid = searchParams.get(UUID_KY);
  // const headerUtm = searchParams.get(UTM_SOURCE);
  // reading data from URL
  const headerUid = searchParams.get(UUID_KY);
  const headerRoomNumber = searchParams.get("roomNumber");
  const headerIsMagnet = searchParams.get(IS_MAGNET);
  const headerIsCatalog = searchParams.get(IS_CATALOG);
  const headerUtm = headerIsMagnet ? "magnet" : headerIsCatalog ? "catalog" : "" //searchParams.get(UTM_SOURCE) ;

  // console.log("searchParams", headerUtm);
  // console.log("searchParamsheaderUid", headerUid);
  // // console.log("location", location);
  // console.log(`["/done","/"].includes( location?.pathname)`, ["/done", "/"].includes(location?.pathname));
  // ------------------------------
  console.log({ useDeliveryData: dData });
  const uuid = headerUid || data;
  const magnet = "";
  const utm = headerUtm || UtmData;

  useEffect(() => {
    if (!UtmData?.userId && !["/done", "/"].includes(location?.pathname)) navigate("/");
    if (data !== uuid) setData(uuid);
    if (headerUid) {
      dInitial(true);
      pInitial();
      cInitial();
      catInitial();
      UtmInitial();
      navigate("/");
      localStorage.removeItem("pop_status");
      if (headerRoomNumber) {
        console.log({ headerRoomNumber });
        setRoomNumber(headerRoomNumber)
      }
      if (headerUtm) {
        console.log("utm ===>", headerUtm);
        utmSetData(utm);
      }
      // if (headerUtm) {
      //   console.log("utm ===>", headerUtm);
      //   utmSetData(utm);
      // } else if (headerUid.split("utm_source=")[1] === "magnet") {
      //   console.log("magnet scan ====>");
      //   utmSetData("magnet");
      // }
    }
  }, [location]);

  // useEffect(() => {
  //   const snowflake1 = document.createElement('img')
  //   snowflake1.src = snow

  //   // const snowflake2 = document.createElement('img')
  //   // snowflake2.src = snowflake

  //   setImages([snowflake1])
  // }, [])

  if (!uuid) {
    return <ErrorTime title={t("hello")} message={t("uuiError")} />;
  }
  return (
    <Layout>

      {/* <div className={"snow-box"}>
        <Snowfall snowflakeCount={150} speed={[0.5, 5]} wind={[-0.1, 1]} radius={[5, 20]} images={
          // [snowflake1]
          images
        } rotationSpeed={[-4.5, 1]} />
      </div> */}
      <Middleware>
        <Routes>
          <Route path={`/`} exact element={<NewCategory />} />
          <Route path={`/cart`} exact element={<Cart />} />
          <Route path={`/child/:id`} exact element={<ChildCategory />} />
          <Route path={`/product/:id`} exact element={<Products />} />
          <Route path={`/product/banner/:bannerId`} exact element={<Products />} />
          <Route path={`/delivery`} exact element={<Delivery />} />
          <Route path={`/payment`} exact element={<Payment />} />
          <Route path={`/done`} exact element={<Done />} />
          <Route path={`/advices`} exact element={<Advices />} />
          <Route path={`/wheather`} exact element={<Wheather />} />
          <Route path={`/privacy`} exact element={<Privacy />} />
          <Route path={`/terms`} exact element={<DistanceSelling />} />
          <Route path={`/return`} exact element={<Return />} />
          <Route path={`/online`} exact element={<OnlinePayment />} />
          <Route path={`/about`} exact element={<About />} />
          <Route path={`/sayfa/*`} element={<></>} />
          <Route
            path={`*`}
            exact
            element={
              <ErrorTime
                title={t("pageNotFound")}
                button={{
                  text: t("backToHome"),
                  link: "/",
                }}
              />
            }
          />
        </Routes>
      </Middleware>

    </Layout>
  );
}
export default App;
