import { useMemo, useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { changePriceFormat } from "utils/calculators";
import { pageView } from "../../GTM";
import "./style.scss";
import styles from "./index.module.scss";

import { useCarts } from "_context/shoppingCart/cartProvider";
import ModalProduct from "components/ModalProduct";
import { ALLERGENS } from "enumeration/allergies";

const { useTranslation } = require("react-i18next");

const ProductCard = ({ handleRemoveCart, handleAddCart, data, isToCart }) => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);

  const isTranslate = useMemo(() => {
    const objData = {};
    data?.translations?.forEach((x) => (objData[x?.language] = x));

    return objData;
  }, [data]);
  const { addCount, cart, removeItem, addCart } = useCarts();

  const textTranslate = isTranslate[i18n?.language];
  //   console.log({ isTranslate, textTranslate }, i18n?.language);

  useEffect(() => {
    pageView(window?.location?.pathname);
  }, []);

  const limitText = (text, limit) => {
    if (text?.length <= limit) {
      return text;
    }
    return text?.slice(0, limit) + "...";
  };
  const allergies = ["celery", "lupin", "milk", "molluscs", "eggs"]
  return (
    <div className="col-12 col-md-6 cursor-pointer">
      <div className="productCard d-flex flex-row">
        <div className="" onClick={() => setShow(true)}>
          <img src={data?.images} className="CardImage" alt={data?.name} />
        </div>
        <div className="card-body ">
          <h4 className="cardText" >
            {limitText(textTranslate?.name || data?.name, 28)}
          </h4>

          <p>{limitText(textTranslate?.shortDesc || data?.shortDesc, 65)}</p>
          <p>{textTranslate?.desc || data?.desc}</p>
          <div className="d-flex flex-row">
            {data?.oldPrice ? (
              <h4 className="orgPrice">
                {"£"}    {changePriceFormat(data?.oldPrice)}
              </h4>
            ) : (
              ""
            )}
            <h4 className="price">
              {"£"}    {changePriceFormat(data?.price)}
            </h4>
          </div>



          <div className={styles.allergies}>
            {
              data.allergies?.map((x) => {
                return (
                  <div className={styles.allergies_item} >
                    <img src={ALLERGENS[x]?.icon} alt={ALLERGENS[x]?.label} className={styles.allergies_icon} />
                    {t(ALLERGENS[x]?.label)}
                  </div>
                )
              })
            }
          </div>

          {isToCart ? (
            <span className="float-right d-flex align">
              {data?.count > 1 ? (
                <button
                  type="button"
                  className="left dec quantityButtonMinus comeTop"
                  id="adetEksi"
                  data-urunid={19}
                  onClick={() => addCount(data, data?.count - 1)}
                >
                  <i className="fa-solid fa-minus" />
                </button>
              ) : (
                <button
                  type="button"
                  className="left dec quantityButtonMinus comeTop"
                  id="adetEksi"
                  data-urunid={19}
                  onClick={() => handleRemoveCart(data)}
                >
                  <i className="fa-solid fa-minus" />
                </button>
              )}

              <Button className="quantityButton">{data?.count}</Button>
              <button
                type="button"
                className=" right inc quantityButtonPlus"
                id="adetArti"
                data-urunid={19}
                onClick={() => addCount(data)}
              >
                {" "}
                <i className="fa-solid fa-plus" />
              </button>
            </span>
          ) : (
            <Button
              onClick={() => handleAddCart(data)}
              className="float-end addBasketButton"
            ></Button>
          )}
          {/* <Button
            onClick={() => addCount(data)}
            className="float-end addBasketButton"
          >
            1
          </Button>
          <Button
            onClick={() => addCount(data, data.count - 1)}
            className="float-end addBasketButton"
          >
            1
          </Button> */}
        </div>
      </div>
      <ModalProduct {...{ show, setShow, handleRemoveCart, handleAddCart, addCount, data, changePriceFormat, textTranslate, isToCart }} />

    </div>
  );
};

export default ProductCard;
