import { getDolarRate, getEuroRate, getHotel, postOrders } from "api/api";
import { PAYMENT_DATA, PAYMENT_DATA_OBJECT, UUID_KY } from "enumeration";
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { changePriceFormat, numberWithCommas } from "utils/calculators";
import { countries, useDelivery } from "_context/delivery/deliveryProvider";
import { usePayment } from "_context/payment/paymentProvider";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useUuid } from "_context/uuid/uuidProvider";
import swal from "sweetalert";
import { useCategory } from "_context/category/provider";
import { useUtmSource } from "_context/utmSource/uuidProvider";
import { pageView } from "../../GTM";
import "./style.scss";
import { Input } from "react-bootstrap";
import PageNavbar from "components/PageNavbar/pageNavbar";
import logo from "assets/papyon-logo.png";
import ModalLayout from "components/ModalLayout";
import Decimal from "decimal.js";
import { useDiscount } from "_context/discount/discountProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";
import compareTranslate from "utils/compareTranslate";
import ImageFullLoad from "components/ImageFullLoad";
import DropDown from "components/DropDown";
import styles from "./index.module.scss";
import MarqueeComponent from "components/MarqueeComponent";

export const addPricePercent = (total) => {
	return total + calculateValueWithPercent(total, 12.5);
};
export const calculateValueWithPercent = (totalValue, partialValue) => {
	return (totalValue / 100) * partialValue;
};
// if (hotel.serviceCharge) totalPrice = addPricePercent(totalPrice);

export default function Payment() {
	const { t, i18n } = useTranslation();
	let [searchParams] = useSearchParams();
	let location = useLocation();

	const errorSearchParams = searchParams.get("error");
	console.log({ location, errorSearchParams });

	const [cardNumber, setCardNumber] = useState("");
	const [expirationDate, setExpirationDate] = useState("");
	const [cvv, setCvv] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [isCheckedError, setIsCheckedError] = useState(false);
	const [isToggleCartModal, setIsToggleCartModal] = useState({
		toggle: false,
		result: null,
	});
	const testHotel = "64f373a227f3e5bbc5a60eaa";

	const { data: uuid } = useUuid();
	const { initial: UtmInitial, data: UtmData, setData: utmSetData, setUserId, setLegalAge } = useUtmSource();

	const { data: delivery, onChange, initial: dInitial } = useDelivery();
	const { data: payment, onSelect, onSelectCurrency, onChangeNote, initial: pInitial } = usePayment();
	const { initial: catInitial } = useCategory();
	const discountState = useDiscount();

	const { cart, initial: cInitial } = useCarts();
	const navigate = useNavigate();

	useEffect(() => {
		if (!cart?.shoppingCart?.length) navigate("/");
	}, [cart]);

	const { data: hotel, isError, error } = useQuery("hotel", () => getHotel(uuid));
	// const { data: currency } = useQuery("currency", () => getDolarRate());
	// if (hotel) hotel.isPhoneRequired = true;
	// console.log("currency =====>", currency);
	const validateEmail = (email) => {
		return (email || "")?.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(
			validation({
				email: true,
				phoneNumber: hotel?.isPhoneRequired,
			})
		),
		mode: "onBlur",
		defaultValues: {
			...delivery,
		},
	});

	// const nameValid = delivery?.name?.trim?.();
	// let emailValid = validateEmail(delivery?.email);
	// const roomValid = delivery?.roomNumber?.trim?.()?.length > 0;
	// const phoneValid = delivery?.phoneNumber?.trim?.()?.length > 0;
	// let acceptGuest = nameValid && roomValid && emailValid;

	// if (hotel?.isPhoneRequired) acceptGuest = acceptGuest && phoneValid;

	const fee = 12.5;
	const serviceFee = hotel?.serviceCharge ? calculateValueWithPercent(cart?.prices, fee) : 0;

	// console.log(
	//   "NAN ==>",
	//   changePriceFormat((serviceFee + cart?.prices) * currency?.euro)
	// );
	console.log("total ==> ==>", serviceFee);
	const initialAll = () => {
		dInitial();
		pInitial();
		cInitial();
		catInitial();
		setUserId();
		setLegalAge(false);
		discountState.initial();
		navigate("/done");
	};

	const onSuccessMutating = (response) => {
		console.log("response = ", response);
		if (response.data.paymentType === "online") {
			console.log("online ");
			// navigate(response.data.paymentUrl);
			if (response?.data?.paymentUrl) window.location.href = response?.data?.paymentUrl;
			else {
				initialAll();
			}
		} else {
			initialAll();
		}
	};

	const {
		data: orders,
		mutate,
		isLoading,
	} = useMutation("orders", (params) => postOrders(uuid, params, UtmData?.isCatalog, UtmData?.isMagnet), {
		onSuccess: onSuccessMutating,
		onError: (error) => {
			console.log({ error });
			// swal(t("error"), t(error?.response?.data?.message), "error");
			if (error?.response?.status === 406) {
				setIsToggleCartModal((p) => ({ ...p, toggle: true, result: error?.response?.data }));
			} else swal(t("error"), t(error?.response?.data?.message), "error");
		},
	});

	console.log({ hotel });

	const accept = payment?.paymentType;

	const handleChangeChecked = (e) => {
		const value = e.target.checked;
		setIsChecked(value);
		setIsCheckedError(false);
		console.log({ value });
	};

	console.log("isOpen", isOpen);
	useEffect(() => {
		pageView(window.location.pathname);
	}, []);

	console.log("paymentType", payment?.paymentType);
	console.log("hotel", { hotel });
	// hotel?.id == testHotel

	const handleChangeUrl = () => {
		navigate("/payment");
	};
	let modalParams = {
		title: t("bankErrorTitle"),
		show: errorSearchParams,
		setShow: handleChangeUrl,
	};

	const isIdVerificationRequired = useMemo(() => {
		return !![...(cart?.shoppingCart || [])]?.find((x) => x?.data?.isIdVerificationRequired);
	}, [cart]);

	let checkAge = !UtmData.legalAge && isIdVerificationRequired;

	useEffect(() => {
		if (checkAge) navigate("/cart");
	}, [checkAge]);

	useEffect(() => {
		if (!hotel) return;
		// console.log("* * * useEffect payment?.paymentType : ", payment?.paymentType);
		// console.log("* * * useEffect PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.value : ", PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.value);
		// console.log("* * * useEffect PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.key : ", PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.key);
		// console.log("* * * useEffect hotel?.payment[PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.key] : ", hotel?.payment[PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.key]);

		if (!payment?.paymentType && hotel?.payment?.[PAYMENT_DATA_OBJECT?.online?.key]) {
			onSelect("paymentType", PAYMENT_DATA_OBJECT?.online?.value);
		}

		if (!payment?.paymentType) return;
		if (PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.value && !hotel?.payment?.[PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.key])
			onSelect("paymentType", false);
	}, [hotel]);

	let orderLimit = hotel?.orderLimit;

	orderLimit = orderLimit && +orderLimit;

	let total = new Decimal(cart?.prices || 0);
	let totalWithService = total;
	if (serviceFee) {
		total = total.add(serviceFee);
		totalWithService = total;
	}

	const orderLimitValid = orderLimit ? orderLimit <= +totalWithService : true;

	const disable = !orderLimitValid;
	const isDisableHotel = hotel?.id == testHotel;
	// if (discountState?.data?.amount) total = total.sub(total.div(100).mul(discountState?.data?.amount || 0));
	// total = total?.toString?.();

	if (discountState?.data?.amount) {
		if (discountState?.data?.isUnit) total = total <= discountState?.data?.amount ? 0 : total.minus(discountState?.data?.amount);
		else total = total.sub(total.div(100).mul(discountState?.data?.amount));
	}
	total = +total.toString();
	if (total < 0) total = 0;

	console.log({ totalWithService: +totalWithService, orderLimit, disable, cart, hotel });

	// let total = cart?.prices || 0;
	// let totalWithService = total;
	// if (serviceFee) {
	// 	total = total + serviceFee;
	// 	totalWithService = total;
	// }

	// let orderLimit = hotel?.orderLimit;

	// const orderLimitValid = hotel?.orderLimit ? hotel?.orderLimit <= totalWithService : true;
	// const disable = !orderLimitValid;
	// const isDisableHotel = hotel?.id == testHotel;
	// console.log({ disable, orderLimit: hotel.orderLimit, totalWithService, prices: cart?.prices, serviceFee });

	const onSubmit = () => {
		// if (!isChecked) return setIsCheckedError(true);
		// if (disable) return;

		if (isLoading) return;
		const values = getValues();
		console.log("* * * onSubmit : ", { values });
		// if (disable) return;
		// return alert();

		mutate({
			name: values?.name,
			roomNumber: values?.roomNumber,
			phoneNumber: `${values?.country?.data?.dial_code} ${values?.phoneNumber}`,
			email: values?.email,
			products: cart?.shoppingCart?.map((x) => ({
				product: x?.data?.id,
				quantity: x?.count,
			})),
			paymentType: payment?.paymentType,
			note: values?.note,
			paymentCurrency: payment?.currency,
			statisticsUserId: UtmData?.userId,
			code: discountState?.data?.code || undefined,
			// phoneNumberCountryCode: values?.country?.value,
		});
	};

	useEffect(() => {
		if (disable) navigate("/cart");
	}, [disable]);

	const modalCartErrorParams = {
		title: t("Order Failed"),
		show: isToggleCartModal?.toggle,
		setShow: setIsToggleCartModal,
	};
	console.log({ countries });
	return (
		<div>
			<ModalLayout {...modalCartErrorParams}>
				<Alert variant={"danger"}>{t("The products you selected are not available right now, try editing and completing the cart.")}</Alert>
				<div>
					<h5>{"Products"}</h5>

					<ul className="p-0">
						{isToggleCartModal?.result?.undefinedProducts?.map((x) => {
							const isTranslate = {};
							x?.translations?.forEach((y) => (isTranslate[y?.language] = y));
							const textTranslate = isTranslate[i18n?.language];

							return (
								<li className="d-flex align-items-center gap-2" key={`undefined-product-${x.id}`}>
									<ImageFullLoad parentProps={{ style: { width: "100px", height: "100px" } }} src={x.images} />
									<div className="d-flex justify-content-between w-100">
										<div className="d-flex flex-column">
											<span> {(textTranslate || x)?.name}</span>
											<span>{x.shortDesc}</span>
										</div>
										<div className="d-flex flex-column">
											<span>
												{" £ "} {numberWithCommas(changePriceFormat(x.price))}
											</span>
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
				<Link to={"/cart"}>
					<Button className={"ml-auto"} variant="outline-success">
						{t("backCart")}
					</Button>
				</Link>
			</ModalLayout>
			<PageNavbar text={t("delivery") + " & " + t("payment")} path="/cart" />
			<ModalLayout {...modalParams}>
				<Alert variant={"danger"}>{t("bankErrorDescription")}</Alert>

				<Button onClick={handleChangeUrl}>ok</Button>
			</ModalLayout>
			<div className="paymentContainer">
				<div className="deliveryBar">
					<div className="location-delivery">
						<i className="fa-solid fa-location-dot locationIcon mr-2" />
						<h4 className="hotelName">
							<MarqueeComponent text={hotel?.name} />
						</h4>
					</div>
					<div className="greenbar ">
						<h4 className="time mb-0">{`${hotel?.averageDeliveryTime || "15-30"} Min`}</h4>
					</div>
				</div>
				{
					// hotel?.id == testHotel
					isDisableHotel ? (
						<div className={"test-container"}>
							<img src={logo} alt="logo" className="logo" />
							<p className="text">{"This is a demo please contact Papyon Room Service Solutions"}</p>
						</div>
					) : (
						<form onSubmit={handleSubmit(onSubmit)} noValidate className="d-flex flex-column w-100">
							<div className={[styles.inputs].join(" ")}>
								<Controller
									control={control}
									ref={register}
									name={fieldNames.name}
									render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
										const handleChange = (e) => {
											change(e);
											onChange(e);
										};

										console.log({ error });
										return (
											<div className="d-flex flex-column w-100">
												<input
													type="text"
													className={`nameInput ${error ? "border-danger" : "border-success"}`}
													placeholder={"*" + t("yourName")}
													id="adi_soyadi"
													// name="name"
													onChange={handleChange}
													// value={delivery?.name}
													isSearchable={false} //
													{...field}
												/>
												{error ? <small className="text-danger mt-1">{compareTranslate(t, error?.message)}</small> : ""}
											</div>
										);
									}}
								/>

								<Controller
									control={control}
									ref={register}
									name={fieldNames.email}
									render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
										const handleChange = (e) => {
											change(e);
											onChange(e);
										};

										console.log({ error });
										return (
											<div className="d-flex flex-column w-100">
												<input
													type="text"
													className={`nameInput ${error ? "border-danger" : "border-success"}`}
													placeholder={"*" + t("email")}
													id="adi_soyadi"
													// name="name"
													onChange={handleChange}
													// value={delivery?.name}
													isSearchable={false} //
													{...field}
												/>
												{error ? <small className="text-danger mt-1">{compareTranslate(t, error?.message)}</small> : ""}
											</div>
										);
									}}
								/>

								{/* <input
									type="text"
									className={`nameInput ${!nameValid ? "border-danger" : "border-success"}`}
									placeholder={"*" + t("yourName")}
									id="adi_soyadi"
									name="name"
									onChange={onChange}
									value={delivery?.name}
									isSearchable={false}
								/> */}
								{/* {
									// isPromotion ?
									<input
										type="text"
										className={`nameInput ${!emailValid ? "border-danger" : "border-success"}`}
										placeholder={"*" + t("email")}
										id="adi_soyadi"
										name="email"
										onChange={onChange}
										value={delivery?.email}
										isSearchable={false}
									/>
									// : ""
								} */}

								<Controller
									control={control}
									ref={register}
									name={fieldNames.roomNumber}
									render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
										const handleChange = (e) => {
											change(e);
											onChange(e);
										};

										console.log({ error });
										return (
											<div className="d-flex flex-column w-100">
												<input
													type="text"
													className={`nameInput ${error ? "border-danger" : "border-success"}`}
													placeholder={"*" + t("roomNumber")}
													id="adi_soyadi"
													// name="name"
													onChange={handleChange}
													// value={delivery?.name}
													isSearchable={false} //
													{...field}
												/>
												{error ? <small className="text-danger mt-1">{compareTranslate(t, error?.message)}</small> : ""}
											</div>
										);
									}}
								/>
								{/* <input
									type="text"
									id="oda_no"
									name="roomNumber"
									className={`nameInput  ${!roomValid ? "border-danger" : "border-success"}`}
									placeholder={"*" + t("roomNumber")}
									onChange={onChange}
									value={delivery?.roomNumber}
									isSearchable={false}
								/> */}

								{hotel?.isPhoneRequired ? (
									<Controller
										control={control}
										ref={register}
										name={fieldNames.phoneNumber}
										render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
											const handleChange = (e) => {
												change(e);
												onChange(e);
											};

											console.log({ error });
											return (
												<div className="d-flex flex-column w-100">
													<div className={["nameInput  d-flex align-items-center gap-2 w-100", styles.phoneNumber].join(" ")}>
														<Controller
															control={control}
															ref={register}
															name={fieldNames.country}
															render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
																const handleChange = (e) => {
																	change(e);
																	onChange({ name: fieldNames.country, value: e });
																};
																return <DropDown {...field} options={countries?.options} onChange={handleChange} />;
															}}
														/>
														<input
															style={{ borderRadius: "10px" }}
															type="number"
															className={`border-0 ${error ? "border-danger" : "border-success"}`}
															placeholder={t("phoneNumber")}
															id="adi_soyadi"
															// name="name"
															onChange={handleChange}
															// value={delivery?.name}
															isSearchable={false} //
															{...field}
														/>
													</div>

													{error ? <small className="text-danger mt-1">{compareTranslate(t, error?.message)}</small> : ""}
												</div>
											);
										}}
									/>
								) : (
									""
								)}

								{/* {hotel?.isPhoneRequired ? (
									<input
										type="text"
										id="oda_no"
										name="phoneNumber"
										className={`nameInput  ${!phoneValid ? "border-danger" : "border-success"}`}
										placeholder={"*" + t("phoneNumberCourier")}
										onChange={onChange}
										value={delivery?.phoneNumber}
										isSearchable={false}
									/>
								) : (
									""
								)} */}

								<Controller
									control={control}
									ref={register}
									name={fieldNames.note}
									render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
										const handleChange = (e) => {
											change(e);
											onChange(e);
										};

										console.log({ error });
										return (
											<div className="d-flex flex-column w-100">
												<textarea
													style={{ borderRadius: "10px" }}
													type="text"
													className={`nameInput ${error ? "border-danger" : "border-success"}`}
													placeholder={t("note")}
													id="adi_soyadi"
													// name="name"
													onChange={handleChange}
													// value={delivery?.name}
													isSearchable={false} //
													{...field}
												/>
												{error ? <small className="text-danger mt-1">{compareTranslate(t, error?.message)}</small> : ""}
											</div>
										);
									}}
								/>
								{/* <textarea
									style={{ borderRadius: "10px" }}
									placeholder={t("orderNote")}
									aria-label="With textarea"
									className="form-control nameInput border-success"
									id="siparisNot"
									name={"note"}
									onChange={onChangeNote}
									value={payment?.note}
									isSearchable={false} //
									rows="3"
								/> */}
							</div>
							<div
								className=""
								style={{
									display: "flex",
									// alignItems: "center",
									justifyContent: "center",
									margin: "auto",
									flexWrap: "wrap",
								}}>
								{PAYMENT_DATA?.map((x) => {
									const active = hotel?.payment[x?.key];
									if (!active) return "";
									if (x.value === "online") {
										// check if payment type is 'online'
										return (
											<div className="paymentCardBox flex-1">
												<button
													type="button"
													className={`btnPayment text-black w-100  ${x?.value === payment?.paymentType ? " successBack" : ""} ${!active ? "disabled" : ""}`}
													key={x.value}
													onClick={() => onSelect("paymentType", x.value)}>
													<i className={`paymentIcon ${x.icon}`} />
													<div className="label">{t(x.label)}</div>
													<i class={`${x?.value === payment?.paymentType ? "fa-solid" : "fa-regular"} fa-circle`}></i>{" "}
												</button>
											</div>
										);
									} else {
										// render a regular button for other payment types
										return (
											<div className="paymentCardBox flex-1">
												<button
													type="button"
													className={`btnPayment text-black w-100  ${x?.value === payment?.paymentType ? " successBack " : ""} ${!active ? "disabled" : ""}`}
													key={x.value}
													onClick={() => onSelect("paymentType", x.value)}>
													<i className={`paymentIcon ${x.icon}`} />

													{"  "}
													<div className="label">{t(x.label)}</div>
													<i class={`${x?.value === payment?.paymentType ? "fa-solid" : "fa-regular"} fa-circle`}></i>
												</button>
											</div>
										);
									}
								})}
							</div>
							{payment?.paymentType === "cash" ? (
								<>
									<h6 className="money">{t("cashCurrency")}</h6>
									<h6 className="moneyInfo">{t("exchangeInfo")}</h6>
								</>
							) : (
								""
							)}
							{/* <div
          className="grid gap-3 mt-3"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {payment?.paymentType === "cash" ? (
            "active" ? (
              <>
                <button
                  className={`maxWidthCurrency currency paymentPadding text-black btnPayment${"eur" === payment?.currency ? " successBack" : ""
                    } ${"active" ? "disabled" : ""}`}
                  type="button"
                  key={"cash"}
                  onClick={() => onSelectCurrency("currency", "eur")}
                >
                  <i className={`paymentIcon "cash"`} />
                  <span className="euroSign">£</span>
                  <br />
                  <span className="cashTotal">
                    {(serviceFee + cart?.prices).toFixed(2)}
                  </span>
                  <br />

                  <i
                    class={`${payment.currency === "eur" ? "fa-solid" : "fa-regular"
                      } fa-circle`}
                  ></i>
                </button>

                <button
                  className={`maxWidthCurrency currency paymentPadding text-black btnPayment${"try" === payment?.currency ? " successBack" : ""
                    } ${"active" ? "disabled" : ""}`}
                  type="button"
                  key={"cash"}
                  onClick={() => onSelectCurrency("currency", "try")}
                >
                  <i className={`paymentIcon "cash"`} />
                  <span className="euroSign">₺</span>
                  <br />
                  <span className="cashTotal">
                    {(
                      (serviceFee + cart?.prices) *
                      currency?.euro *
                      1.08
                    ).toFixed(2)}{" "}
                  </span>
                  <br />
                  <i
                    class={`${payment.currency === "try" ? "fa-solid" : "fa-regular"
                      } fa-circle mb-3`}
                  ></i>
                </button>
                <button
                  className={`maxWidthCurrency currency text-black paymentPadding btnPayment${"usd" === payment?.currency ? " successBack" : ""
                    } ${"active" ? "disabled" : ""}`}
                  type="button"
                  key={"cash"}
                  onClick={() => onSelectCurrency("currency", "usd")}
                >
                  <i className={`paymentIcon "cash"`} />
                  <span className="euroSign">$</span>
                  <br />
                  <span className="cashTotal">
                    {changePriceFormat(
                      ((serviceFee + cart?.prices) * currency?.euro) /
                      currency?.dollar
                    )}
                  </span>
                  <br />
                  <i
                    class={`${payment.currency === "usd" ? "fa-solid" : "fa-regular"
                      } fa-circle mb-3`}
                  ></i>
                </button>
              </>
            ) : null
          ) : null}
        </div> */}
							{/* {payment?.paymentType != "cash" ? (
          <div className="totalContainer">
            <span className="totalTitle bold">Total :</span>
            <span className="totalPrice">
              {" £ "}
              {(serviceFee + cart?.prices).toFixed(2)}
            </span>
          </div>
        ) : (
          ""
        )} */}

							<Controller
								control={control}
								ref={register}
								name={fieldNames.privacy}
								render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
									const handleChange = (e) => {
										change(e);
										handleChangeChecked(e);
									};

									console.log({ error });
									return (
										// <div className="d-flex flex-column w-100">
										// 	<textarea
										// 		style={{ borderRadius: "10px" }}
										// 		type="text"
										// 		className={`nameInput ${error ? "border-danger" : "border-success"}`}
										// 		placeholder={t("note")}
										// 		id="adi_soyadi"
										// 		// name="name"
										// onChange={handleChange}
										// // value={delivery?.name}
										// isSearchable={false} //
										// {...field}
										// 	/>
										// 	{error ? <small className="text-danger mt-1">{compareTranslate(t, error?.message)}</small> : ""}
										// </div>
										<div className="row mx-3 mt-3">
											<div className="col-12">
												<div type={"checkbox"} className="d-flex align-items-center">
													<Form.Check.Input
														style={{
															width: "25px",
															minWidth: "25px",
															height: "25px",
															marginRight: "10px",
														}}
														className={` ${error ? "border-danger" : "border-success"}`}
														type={"checkbox"}
														isValid
														onChange={handleChange}
														// value={delivery?.name}
														isSearchable={false} //
														{...field}
													/>
													<div className="d-flex">
														<p className="m-0">
															By submitting you agree to our{" "}
															<Link to={"/privacy"} className={`mr-1 text-decoration-underline ${isCheckedError ? "text-danger" : ""}`}>
																{"Privacy policy"}
															</Link>
															{" & "}
															<Link to={"/terms"} className={`mr-1 text-decoration-underline ${isCheckedError ? "text-danger" : ""}`}>
																{"Terms and conditions"}
															</Link>
														</p>
													</div>
												</div>
												{error ? <small className="text-danger ">{compareTranslate(t, error?.message)}</small> : ""}
											</div>
										</div>
									);
								}}
							/>

							<div className="my-3 mt-3 mx-auto tradingContainer">
								<a className=" bold ">{"Country of trading: United Kingdom"}</a>
								<br />
								<a className=" bold text-decoration-underline" href={"https://www.papyonsolutions.com"} target="_blank">
									{"www.papyonsolutions.com"}
								</a>
							</div>

							{!isDisableHotel ? (
								<button
									className={`completeContainer mt-4 `}
									type={"submit"}
									disabled={isLoading}
									// onClick={!disable ? onSubmit : null}
								>
									{/* ${disable ? "completeContainerNotValid" : ""} */}
									{/* {disable ? t("minimumCart", { author: orderLimit + symbol }) : t("completeOrder")} */}
									{/* {t("completeOrder")} */}
									{isLoading ? <Spinner animation="border" /> : disable ? t("minimumCart", { author: hotel?.orderLimit + "£" }) : t("completeOrder")}
								</button>
							) : (
								""
							)}
							{/* {!acceptGuest ? (
								<div className="completeContainerNotValid">{t("notValid")}</div>
							) : isLoading ? (
								<div className="completeContainerNotValid">...</div>
							) : !isDisableHotel ? (
								<div className={`completeContainer mt-4 ${disable ? "completeContainerNotValid" : ""}`} onClick={!disable && onSubmit}>
									{disable ? t("minimumCart", { author: hotel?.orderLimit + "£" }) : t("completeOrder")}
								 
									{isLoading ? <Spinner animation="border" /> : null}
								</div>
							) : (
								""
							)}{" "} */}
						</form>
					)
				}
			</div>
		</div>
	);
}
