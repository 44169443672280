import React, { createContext, useState, useContext, useEffect, useMemo } from "react";
import {
  handleAddProduct,
  handleChangeCount,
  handleRemoveProduct,
} from "./helperFunc";
import { useUtmSource } from "_context/utmSource/uuidProvider";

const CartContext = createContext();
export const useCarts = () => useContext(CartContext);

function getInitialState() {
  const notes = localStorage.getItem("basket");
  return notes
    ? JSON.parse(notes)
    : {
      shoppingCart: [],
      total: 0,
      object: {},
    };
}

export default function CartProvider({ children }) {
  const [data, setData] = useState(getInitialState);

  const { data: utm, setLegalAge } = useUtmSource();


  const isIdVerificationRequired = useMemo(() => {
    return !!([...data?.shoppingCart || []]?.find((x) => x?.data?.isIdVerificationRequired))
  }, [data]);

  useEffect(() => {
    localStorage.setItem("basket", JSON.stringify(data));
  }, [data]);

  console.log({ data });

  const addCart = (cart) => {
    console.log("* * * CartProvider :", { cart });
    // utm.legalAge
    if (!isIdVerificationRequired && cart.isIdVerificationRequired) {
      // alert()
      setLegalAge(false)
    }

    setData(handleAddProduct({ state: data, data: cart }));
  }
  const addCount = (cart, count) => {
    console.log("* * * CartProvider :", { cart, count });
    if (count == undefined) count = cart?.count + 1
    if (cart?.medicine && count > 2) count = 2;
    setData(handleChangeCount({ state: data, cart, count }));
  }
  const removeItem = (cart) =>
    setData(handleRemoveProduct({ state: data, cart }));

  const initial = () =>
    setData({
      shoppingCart: [],
      total: 0,
      object: {},
    });



  // utm.legalAge

  console.log("* * * CartProvider :", { isIdVerificationRequired, utm });
  return (
    <CartContext.Provider
      value={{ cart: data, addCart, addCount, removeItem, initial }}
    >
      {children}
    </CartContext.Provider>
  );
}
