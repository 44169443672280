import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import payment from "../../assets/payment.png";
function OnlinePayment() {
  const { t, i18n } = useTranslation();
  return (
    <section className>
      <div className="osahan-home-page">
        <div className="osahan-payment">
          <div className=" border-bottom px-3 pt-3 pb-5 d-flex align-items-center">
            <a className="text-white font-weight-bold" href="sepet.html" data-lang="kapat">
              {" "}
              <Link to="/cart">
                <Button variant="danger">
                  {" "}
                  <i className="fa-solid fa-angle-left" /> {t("back")}
                </Button>
              </Link>
            </a>

            <a className="toggle" href="#">
              <span />
            </a>
            {/* <h4 className="font-weight-bold m-0  ml-auto" data-lang="sepetim">
            {t("deliveryInfo")}
          </h4> */}
          </div>
          {/* checkout */}
          {/* <div className="p-3 osahan-cart-item">
            <div className="osahan-cart-item-profile bg-white rounded shadow p-3 mt-n5">
              <div className="d-flex flex-column">
                <div className="custom-control custom-radio mb-2 px-0 p-teslimat">
                  <input
                    type="radio"
                    id="customRadio1"
                    name="customRadio"
                    className="custom-control-input input-ab"
                    defaultChecked
                  />
                  <label
                    className="custom-control-label border osahan-check p-3 w-100 rounded border-primary"
                    htmlFor="customRadio1"
                  >
                    <i className="fa-solid fa-location-dot mr-2" />
                    <span className="hotel-name">hotelname</span>
                    <br />
                  </label>
                </div>
              </div>
            </div>
          </div> */}
          <div className="accordion px-3 pb-3" id="accordionExample">
            <div className="osahan-card bg-white overflow-hidden shadow rounded mb-2">
              <div className="osahan-card-header" id="headingOne">
                <h2 className="mb-0">
                  <button className="d-flex p-3 align-items-center btn btn-link w-100">
                    <i className="fa-regular fa-credit-card mr-3" />{" "}
                    <span className="fs13" data-lang="teslimat_bilgileriniz">
                      {t("onlinePayment")}
                    </span>
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div className="osahan-card-body border-top p-3">
                  <form>
                    <div className="form-row">
                      <div className="col-md-12 form-group">
                        <label className="form-label font-weight-bold small" data-lang="oda_numarasi">
                          {t("cardNumber")}
                        </label>
                        <input
                          placeholder="Card Number"
                          type="text"
                          //   className={`form-control ${!roomValid ? "border-danger" : "border-success"}`}
                          className={"form-control border-success"}
                          id="oda_no"
                          name="roomNumber"
                        //   onChange={onChange}
                        //   value={delivery?.roomNumber}
                        />
                      </div>
                      <div className="col-md-12 form-group">
                        <label className="form-label font-weight-bold small" data-lang="ad_soyad">
                          {t("expireDate")}
                        </label>
                        <input
                          placeholder="Expire Date"
                          type="text"
                          //   className={`form-control ${!nameValid ? "border-danger" : "border-success"}`}
                          className={"form-control border-success"}
                          id="adi_soyadi"
                          name="name"
                        />
                      </div>
                      <div className="col-md-12 form-group">
                        <label className="form-label font-weight-bold small" data-lang="ad_soyad">
                          {t("cvv")}
                        </label>
                        <input
                          placeholder="Cvv"
                          type="text"
                          //   className={`form-control ${!nameValid ? "border-danger" : "border-success"}`}
                          className={"form-control border-success"}
                          id="adi_soyadi"
                          name="name"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="shadow bg-white rounded">
                <img className="paymentLogo" src={payment} alt="Image 1" />
              </div>

              <div
                className="d-sm-flex justify-content-center justify-content-sm-between py-2 mt-4"
                style={{ display: "flex" }}
              >
                <Link
                  to={{
                    pathname: `/about`,
                  }}
                >
                  <p className="font-weight-normal mb-3 supportText ">Hakkımızda & İletişim</p>
                </Link>
              </div>
            </div>

            {/* <div className="shadow bg-white rounded p-3 mt-2">
              <h7 className=" mb-0">
                <span className="colorRed">{t("deliveryInfo")}</span>
              </h7>
            </div> */}
          </div>
        </div>
      </div>

      <Link
        to={{
          pathname: `/payment`,
          // search: `?${[UUID_KY]}=${uuid}`,
        }}
        // className={`btn btn-success btn-block btn-lg fixed-bottom ${!accept && "disabled"}  `}
        className={"btn btn-success btn-block btn-lg fixed-bottom "}
        style={{ color: "#fff" }}
        id="odemeYap"
      >
        {/* {numberWithCommas(cart?.prices)} {" £"}  */}
        <span data-lang="odeme_yap_b">{t("payment")}</span>
      </Link>
    </section>
  );
}

export default OnlinePayment;
