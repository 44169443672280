import React, { useEffect, useState } from "react";
import "./style.scss";

// import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
// import { input } from "react-bootstrap";
// import test from "../../assets/test.png";
import NavBar from "../navbar";

import { Link, Redirect, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import * as api from "../../api/api";
import ErrorTime from "components/ErrorTime";
// import Spinner from "react-bootstrap/Spinner";
import BottomNav from "components/bottomNav";
import { useUuid } from "_context/uuid/uuidProvider";
import { useCategory } from "_context/category/provider";
import { useUtmSource } from "_context/utmSource/uuidProvider";
// import { t } from "i18next";
// import { useMemo } from "react";
import { useTranslation } from "react-i18next";
// import fall from "../../assets/fall.png";
// import { tr } from "locales";
// import snow from "../../assets/snow.png";
// import dis from "../../assets/tdis.png";
// import turkey from "../../assets/dis.png";
// import { Card } from "react-bootstrap";
import { pageView } from "../../GTM";
// import FullScreenPopup from "components/languagePopup";
import SearchBar from "reuse/SearchBar";
import HomePageSlider from "./Slider/index";
import FullScreenOffOrderPopup from "components/OffOrderPopup";
import Skeleton from "react-loading-skeleton";
import ImageFullLoad from "components/ImageFullLoad";
import { useDelivery } from "_context/delivery/deliveryProvider";
import styles from "./index.module.scss";
import Icons from "reuse/Icons";

const NewCategory = () => {
	// const fadeImages = [
	//   {
	//     url: "https://d35ns7pomhypr0.cloudfront.net/96fc1bb5-cfef-46b5-a057-6927c2618fae-w.png",
	//   },
	//   {
	//     url: "https://d35ns7pomhypr0.cloudfront.net/0d659f18-6634-4fdd-b3b2-12f67144e34d-fav.png",
	//   },
	// ];

	const { data: uuid } = useUuid();
	const { data: dData } = useDelivery();

	const { t, i18n } = useTranslation();

	const { initial: UtmInitial, data: UtmData, setData: utmSetData, setUserId } = useUtmSource();

	const [visible, setVisible] = useState(false);
	const [visibleOffOrder, setVisibleOffOrder] = useState(false);

	const handleSuccessCategory = (data) => {
		console.log("* * * handleSuccessCategory : ", { data });
		const statisticsUserId = data.data.statisticsUserId;
		if (statisticsUserId) setUserId(statisticsUserId);
	};

	let {
		data: { data } = {},
		isError,
		error,
		isLoading,
		isFetching,
		refetch,
	} = useQuery(
		"parentCategories",
		() =>
			// api.getParentCategories(
			// 	uuid,
			// 	//  UtmData?.isCatalog, UtmData?.isMagnet,
			// 	UtmData?.isMagnet ? "magnet" : UtmData?.isCatalog ? "catalog" : undefined,
			// 	!UtmData?.userId,
			// 	dData?.staticRoomNumber,
			// 	!UtmData?.userId && UtmData.language
			// ),
			api.getParentCategories(
				uuid,
				UtmData?.isMagnet ? "magnet" : UtmData?.isCatalog ? "catalog" : undefined,
				UtmData.language && !UtmData?.userId,
				dData?.staticRoomNumber,
				!UtmData?.userId && UtmData.language
			),
		{
			enabled: !!uuid && !!UtmData.language,
			// staleTime: 100000,
			retry: false,
			onSuccess: handleSuccessCategory,
		}
	);

	useEffect(() => {
		if (UtmData?.userId || !UtmData?.language) return;
		refetch();
		// queryClient.invalidateQueries("parentCategories");
	}, [UtmData?.language]);

	const { data: banner } = useQuery("banner", () => api.getBanner(uuid), {
		enabled: !!uuid,
		retry: false,
	});
	console.log("Banner ===>", banner);

	const { data: catData, setParent, setChild, initial } = useCategory();

	console.log({ data, error, catData, UtmData });

	// const handleCloseLanguage = () => {
	//   localStorage.setItem("pop_status", 1);
	//   setVisible(false);
	// }

	const handleCloseOrder = () => {
		localStorage.setItem("pop_order", 1);
		setVisibleOffOrder(false);
	};

	useEffect(() => {
		pageView(window?.location?.pathname);
		console.log("pathName", window.location.pathname);
	}, []);

	// useEffect(() => {
	//   if (!UtmData?.userId && !isFetching) refetch()
	// }, [UtmData?.userId]);

	// useEffect(() => {
	//   let pop_status = localStorage.getItem("pop_status");
	//   // let pop_order = localStorage.getItem("pop_order");
	//   if (!pop_status) {
	//     setVisible(true);
	//   }
	//   // if (pop_status && !pop_order) {
	//   //   setVisibleOffOrder(true);
	//   //   // localStorage.setItem("pop_order", 1);
	//   // }
	// }, [visible]);

	// if (isLoading) {
	//   return (
	//     <div className="text-center">
	//       {" "}
	//       <Spinner animation="border" />
	//     </div>
	//   );
	// }
	// isLoading = true
	if (isError) {
		return <ErrorTime message={error?.response?.data?.message} />;
	}
	console.log("visible", visible);

	return (
		<div className="container">
			{/* {visible && (
        <FullScreenPopup
          isOpen={visible}
          onRequestClose={handleCloseLanguage}
        />
      )} */}

			{/* {visibleOffOrder && ( */}
			{/* <FullScreenOffOrderPopup isOpen={visibleOffOrder} onRequestClose={handleCloseOrder} /> */}
			{/* )} */}

			{/* <div className="inputWrapper">
        <i className="fa-solid fa-search inputIcon" />
        <input className="searchInput" placeholder="Search..."></input>
      </div> */}
			{/* {visible ? "" : <NavBar />} */}
			<NavBar />
			{/* <SearchBar />
      <div className="slide-container mt-11">
        <HomePageSlider data={banner} {...{ setChild, setParent }} />
      </div>
      <div className="row g-2 cardContainer">
        {data?.categories?.map((parameter) => {
          const isTranslate = parameter?.translations?.reduce((obj, x) => {
            obj[x.language] = x;
            return obj;
          }, {});
          const textTranslate = isTranslate?.[i18n?.language];

          return (
            <Link
              key={parameter.id}
              to={{
                pathname:
                  parameter?.children > 0
                    ? `/child/${parameter.id}`
                    : `/product/${parameter.id}`,
              }}
              className="col-3 col-sm-3 col-md-3"
              onClick={() => {
                if (!parameter?.children) {
                  setChild(false);
                }
                setParent(parameter);
                console.log("parameterss", parameter);
              }}
            >
              <div className="catCard">
                <img src={parameter.image} className="catImage" alt="..." />
                <span className="catName">
                  {" "}
                  {textTranslate?.name || parameter.name}
                </span>
              </div>
            </Link>
          );
        })}
      </div> */}

			{isLoading ? <Skeleton height={30} className="mb-2" /> : <SearchBar />}
			<div className="slide-container mt-11">
				<HomePageSlider data={banner} {...{ setChild, setParent }} />
			</div>
			<div className="row cardContainer">
				{(data?.categories || new Array(15).fill())?.map((parameter) => {
					const isTranslate = parameter?.translations?.reduce((obj, x) => {
						obj[x.language] = x;
						return obj;
					}, {});
					const textTranslate = isTranslate?.[i18n?.language];

					return (
						<Link
							key={parameter?.id}
							to={{
								pathname: parameter?.children > 0 ? `/child/${parameter?.id}` : `/product/${parameter?.id}`,
							}}
							className="col-md-3 col-sm-3 col-3"
							onClick={(e) => {
								if (!parameter?.id) e.preventDefault();
								if (!parameter?.children) {
									setChild(false);
								}
								setParent(parameter);
								// console.log("parameterss", parameter);
							}}>
							<div className={["catCard", styles.cardBox].join(" ")}>
								{/* <div className={styles.heart}>
                    <Icons.HeartFill />
                  </div> */}
								{/* <img src={parameter.image} className="catImage" alt="..." /> */}
								<ImageFullLoad src={parameter?.image} className="catImage" />
								<span className="catName"> {isLoading ? <Skeleton className="mt-1" /> : textTranslate?.name || parameter?.name}</span>
							</div>
						</Link>
					);
				})}
			</div>

			{<div className="empty-space-cat" />}

			<BottomNav />
		</div>
	);
};
export default NewCategory;

{
	/*
	 */
}
