import styles from "./index.module.scss";
import useClickTarget from "hooks/useClickTarget";
import { useRef, useState } from "react";
import { t } from "i18next";
import Icons from "reuse/Icons";

const DropDown = ({ options, value, onChange, label }) => {
	const dropdownRef = useRef(null);
	const [showPopup, setShowPopup] = useState(false);
	const [search, setSearch] = useState();

	const onClose = () => {
		setShowPopup(false);
		setSearch();
	};

	const target = useClickTarget(dropdownRef, showPopup, setShowPopup);
	console.log("* * * DropDown : ", { options, value, onChange, label });
	const handleSearch = (e) => {
		const value = e.target.value?.toLocaleLowerCase();
		if (!value) return setSearch();
		let final = options?.filter((x) => x.searchStrings?.some((y) => y?.toLocaleLowerCase()?.includes(value)));
		console.log("* * * handleSearch", { value, options, final });
		setSearch(final);
	};

	return (
		<div className={styles.box} ref={dropdownRef}>
			{label ? <label className={styles.label}>{"label"}</label> : ""}
			<div className={styles.popupContainer}>
				<div onClick={() => setShowPopup((p) => !p)} className={[styles.value, "d-flex align-items-center gap-2"].join(" ")}>
					<div>{value?.valueLabel || value?.label || ""}</div>

					<div>
						<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
							<path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
						</svg>
					</div>
				</div>
				<div className={styles.popupBox}>
					{showPopup ? (
						<div className={[styles.popup, "shadow"].join(" ")}>
							<div className={styles.searchBox}>
								<Icons.Search />
								<input
									placeholder={t("search")}
									type="text"
									className={["form-control border-success", styles.input].join(" ")}
									name="search-bar"
									onChange={handleSearch}
								/>
							</div>
							<ul className={[styles.items].join(" ")}>
								{(search || options)?.map((x) => (
									<li
										onClick={() => {
											onChange?.(x);
											onClose();
										}}
										key={x.value}
										className={[styles.item, value?.value === x.value && styles.active].join(" ")}>
										{x.label}
									</li>
								))}
							</ul>
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	);
};

export default DropDown;
