const convertor = {
	object: (x) => ({
		valueLabel: (
			<div className="d-flex align-items-center gap-2">
				<div style={{ display: "flex", alignItems: "center", fontSize: "30px", height: "20px" }}>{x.emoji}</div>
				<p
					style={{ marginBottom: 0, fontSize: "12px", textOverflow: "ellipsis", maxWidth: "100px", whiteSpace: "nowrap", overflow: "hidden" }}
					className="ellipsis">
					{x.dial_code}
				</p>
				{/* {x.emoji} */}
			</div>
		),
		label: (
			<div className="d-flex align-items-center gap-3">
				<div style={{ fontSize: "30px" }}>{x.emoji}</div>
				<div>
					<p
						style={{ marginBottom: 0, fontSize: "12px", textOverflow: "ellipsis", maxWidth: "100px", whiteSpace: "nowrap", overflow: "hidden" }}
						className="ellipsis">
						{x.name}
					</p>
					<p>{x.dial_code}</p>
				</div>
			</div>
		),
		value: x.code,
		data: x,
		searchStrings: [x.code, x.name, x.dial_code],
	}),
	array: (x) => x?.map?.(convertor.object),
};

const countriesSelector = {
	convertor,
};

export default countriesSelector;
