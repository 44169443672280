import React, { useEffect } from "react";
import { LANGUAGE_DATA, LANGUAGE_DATA_OBJECT, UUID_KY } from "../../enumeration";
import Select from "react-select";
import "./style.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { useTranslation } from "react-i18next";
import { getHotel } from "api/api";
import { useQuery } from "react-query";
import { useUuid } from "_context/uuid/uuidProvider";
import flag from "../../assets/united-states 1.png";
import { Link } from "react-router-dom";
import { useUtmSource } from "_context/utmSource/uuidProvider";
import MarqueeComponent from "components/MarqueeComponent";

const NavBar = () => {
	const { data: uuid } = useUuid();
	let { data, isError, error, isLoading } = useQuery("hotel", () => getHotel(uuid), {
		enabled: !!uuid,
		retry: false,
	});
	const { setLanguage } = useUtmSource();

	const { t, i18n } = useTranslation();
	console.log({ i18n });

	const changeLanguageHandler = (selectedOption) => {
		const value = selectedOption.value;
		i18n.changeLanguage(value);
		setLanguage(value);
	};
	// const validLanguage = LANGUAGE_DATA.filter((x) => {
	//   const valid = data?.languages.find(y => y.code == x.value)
	//   if (!valid) return false
	//   return true;
	// })
	const languageOptions = data?.languages?.map((x) => {
		return {
			value: x.code,
			label: (
				<div className="langOptions">
					{x.image ? <img src={x.image} alt={x.name} className="languageOptionImage" /> : ""}
					{x.name}
				</div>
			),
		};
	});
	// isLoading = true
	const value = languageOptions?.find((x) => x.value === i18n.language);

	useEffect(() => {
		if (!data) return;
		if (!LANGUAGE_DATA_OBJECT[i18n.language]) {
			if (LANGUAGE_DATA_OBJECT[data?.defaultLanguage]) i18n.changeLanguage(data?.defaultLanguage);
			else i18n.changeLanguage(LANGUAGE_DATA[0]?.value);
		}
	}, [data]);

	console.log({ data, languageOptions, value });

	return (
		<header className="d-flex">
			<nav className="navbar navbar-expand-lg">
				<div className="navbarSpace d-flex align-items-center w-100 justify-content-between">
					<div className="navBarBrand">
						{isLoading ? (
							<Skeleton width={70} height={15} />
						) : (
							<Link className=" d-flex align-items-center mr-1" to={"/"}>
								<MarqueeComponent text={data?.name} />
							</Link>
						)}
					</div>

					{isLoading ? (
						<Skeleton width={100} height={35} />
					) : data ? (
						<Select
							className="languageSelect"
							options={languageOptions}
							value={value}
							onChange={changeLanguageHandler}
							preventScroll={true} // Disable screen zoom on select
							isSearchable={false} //
						/>
					) : (
						""
					)}
				</div>
			</nav>
		</header>
	);
};

export default NavBar;

// import React, { useEffect } from "react";
// import {
//   LANGUAGE_DATA,
//   LANGUAGE_DATA_OBJECT,
//   UUID_KY,
// } from "../../enumeration";
// import Select from "react-select";
// import "./style.scss";

// import { useTranslation } from "react-i18next";
// import { getHotel } from "api/api";
// import { useQuery } from "react-query";
// import { useUuid } from "_context/uuid/uuidProvider";
// import flag from "../../assets/united-states 1.png";
// import { Link } from "react-router-dom";
// import { Button } from "react-bootstrap";

// const NavBar = ({ fromTerms }) => {
//   const { data: uuid } = useUuid();
//   const { data, isError, error } = useQuery("hotel", () => getHotel(uuid), {
//     enabled: !!uuid,
//   });
//   const { t, i18n } = useTranslation();
//   console.log({ i18n });

//   const changeLanguageHandler = (selectedOption) => {
//     const value = selectedOption.value;
//     i18n.changeLanguage(value);
//   };
//   // const validLanguage = LANGUAGE_DATA.filter((x) => {
//   //   const valid = data?.languages.find(y => y.code == x.value)
//   //   if (!valid) return false
//   //   return true;
//   // })
//   const languageOptions = data?.languages?.map((x) => {

//     return ({
//       value: x.code,
//       label: (
//         <div className="langOptions">
//           {x.image ? <img src={x.image} alt={x.name} className="languageOptionImage" /> : ""}
//           {x.name}
//         </div>
//       ),
//     })
//   });

//   const value = languageOptions?.find((x) => x.value === i18n.language)

//   useEffect(() => {
//     if (!data) return;
//     if (!LANGUAGE_DATA_OBJECT[i18n.language]) {
//       if (LANGUAGE_DATA_OBJECT[data?.defaultLanguage])
//         i18n.changeLanguage(data?.defaultLanguage);
//       else i18n.changeLanguage(LANGUAGE_DATA[0]?.value);
//     }
//   }, [data]);

//   console.log({ data, languageOptions, value });

//   return (
//     <header>
//       <nav className="navbar navbar-expand-lg">
//         <div className="container-fluid navbarSpace">
//           <Link to={"/"}>
//             <span className="navBarBrand">
//               {
//                 fromTerms ?
//                   <Link to="/payment">
//                     <Button className="d-flex align-items-center" >
//                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
//                         <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
//                       </svg>
//                       <div className="mx-1"></div>
//                       Back To Payment
//                     </Button>
//                   </Link>

//                   : data?.name
//               }
//             </span>
//           </Link>
//           {data ? <Select
//             className="languageSelect"
//             options={languageOptions}
//             value={value}
//             onChange={changeLanguageHandler}
//             preventScroll={true} // Disable screen zoom on select
//             isSearchable={false} //
//           /> : ""}
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default NavBar;
