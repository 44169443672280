import { getHotel, getHotelMenu, getRecommendedProduct } from "api/api";
import BottomNav from "components/bottomNav";
import ProductCard from "components/ProductCard";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import FindMe from "reuse/FindMe";
import swal from "sweetalert";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useUuid } from "_context/uuid/uuidProvider";

export default function Advices() {
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    sort: "asc",
  });

  const [stateData, setStateData] = useState({
    result: [],
    total: 0,
  });
  const { t, i18n } = useTranslation();
  const { addCount, cart, removeItem, addCart } = useCarts();
  const { data: uuid } = useUuid();
  const handleSuccess = (data) => {
    let newData = [...stateData?.result];
    newData = [...newData, ...data.result];
    console.log({ newData }, data.result);
    setStateData({ result: newData, total });
  };

  const { data, isError, error, isLoading, refetch } = useQuery(
    ["recommended", pagination],
    () => getRecommendedProduct(uuid, pagination),
    { cacheTime: 0, onSuccess: handleSuccess }
  );
  const total = data?.total;
  const maxPage = Math.ceil(total / pagination?.limit);

  const handleRemoveCart = (param) => {
    removeItem(param);
    // swal(t("successful"), t("removeFromBasket"), "success");
  };

  const handleAddCart = (param) => {
    addCart(param);
    console.log({ param });
    // swal(t("successful"), t("productAddToCart"), "success");
  };

  const handleNextPage = (props) => {
    const nextPage = pagination.page + 1;
    if (pagination?.page < maxPage)
      setPagination((p) => ({ ...p, page: nextPage }));
  };

  return (
    <section className="category category-p  mb-3 ">
      <div className="container-fluid">
        <div className="category-title text-start px-2">
          {t("opportunityProducts")}
        </div>
        <div className="  g-4">
          <div className="row  mb-4 px-2">
            {stateData?.result?.map((x) => {
              const isToCart = cart?.object[x.id];
              return (
                <ProductCard
                  {...{ handleRemoveCart, handleAddCart, isToCart }}
                  data={x}
                  key={x.id}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className={"loader"}>
        {isLoading ? (
          <div className="text-center">
            {" "}
            <Spinner animation="border" />
          </div>
        ) : stateData?.result?.length ? (
          <FindMe callback={handleNextPage} margin={"150px"} />
        ) : (
          // !isLoading ? <NotFound /> :
          ""
        )}
      </div>

      <BottomNav />
    </section>
  );

  // <section className="category category-p">
  //   <div className="container-fluid">
  //     <div className="category-title">FIRSAT ÜRÜNLER</div>
  //     <div className="row row-cols-2 row-cols-md-2 g-4 mb-4">
  //       <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  //         <div className="col">
  //           <div className="card h-100">
  //             <div className="img-card">
  //               {" "}
  //               <img
  //                 src="assets/img/sogukicecek.png"
  //                 className="card-img-top"
  //                 alt="..."
  //               />
  //               <span>₺15.00</span>
  //               <b>
  //                 <i className="fa-solid fa-cart-plus" />
  //               </b>
  //             </div>
  //             <div className="card-body">
  //               <h5 className="card-title-incategory">Kola</h5>
  //               <p>Seçenekler: Coca-Cola, Pepsi </p>
  //             </div>
  //           </div>
  //         </div>
  //       </a>
  //       <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  //         <div className="col">
  //           <div className="card h-100">
  //             <div className="img-card">
  //               {" "}
  //               <img
  //                 src="assets/img/pankek.png"
  //                 className="card-img-top"
  //                 alt="..."
  //               />
  //               <span>₺15.00</span>
  //               <b>
  //                 <i className="fa-solid fa-cart-plus" />
  //               </b>
  //             </div>
  //             <div className="card-body">
  //               <h5 className="card-title-incategory">Pankek</h5>
  //               <p>Mısır Unlu Pankek, Tarçınlı </p>
  //             </div>
  //           </div>
  //         </div>
  //       </a>
  //       <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  //         <div className="col">
  //           <div className="card h-100">
  //             <div className="img-card">
  //               {" "}
  //               <img
  //                 src="assets/img/sushi.png"
  //                 className="card-img-top"
  //                 alt="..."
  //               />
  //               <span>₺125.00</span>
  //               <b>
  //                 <i className="fa-solid fa-cart-plus" />
  //               </b>
  //             </div>
  //             <div className="card-body">
  //               <h5 className="card-title-incategory">Sushi</h5>
  //               <p>Kalamar, Yılan Balığı </p>
  //             </div>
  //           </div>
  //         </div>
  //       </a>
  //       <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  //         <div className="col">
  //           <div className="card h-100">
  //             <div className="img-card">
  //               {" "}
  //               <img
  //                 src="assets/img/salata.png"
  //                 className="card-img-top"
  //                 alt="..."
  //               />
  //               <span>₺60.00</span>
  //               <b>
  //                 <i className="fa-solid fa-cart-plus" />
  //               </b>
  //             </div>
  //             <div className="card-body">
  //               <h5 className="card-title-incategory">Salata</h5>
  //               <p>Yeşillik, Biber, Domates, Soğan... </p>
  //             </div>
  //           </div>
  //         </div>
  //       </a>
  //     </div>
  //   </div>
  // </section>
  // );
}
